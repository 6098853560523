<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        schedule.horarioId == 0
          ? $t("Routes.ScheduleAdd")
          : $t("Routes.ScheduleEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.schedule.columns.name')"
              rules="required|max:50"
            >
              <b-form-group
                :label="$t('humanResources.schedule.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="schedule.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.schedule.columns.startTurn')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.schedule.columns.startTurn')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-timepicker
                  v-model="schedule.inicioTurno"
                  locale="en"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.schedule.columns.endTurn')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.schedule.columns.endTurn')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-timepicker
                  v-model="schedule.finTurno"
                  locale="en"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('humanResources.schedule.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="schedule.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingSchedule" small class="mr-1" />
            {{ schedule.horarioId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormTimepicker,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, max } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormTimepicker,

    ValidationProvider,
    ValidationObserver,

  },
  setup() {
    const addingSchedule = ref(false)
    const schedule = ref({
      horarioId: 0,
      nombre: '',
      descripcion: '',
      inicioTurno: '',
      finTurno: '',
      archivado: false,
    })

    const blankSchedule = {
      horarioId: 0,
      nombre: '',
      descripcion: '',
      inicioTurno: '',
      finTurno: '',
      archivado: false,
    }

    const {
      fetchSchedule,
      createSchedule,
      updateSchedule,
    } = humanResources()

    const resetSchedule = () => {
      schedule.value = JSON.parse(JSON.stringify(blankSchedule))
    }

    if (router.currentRoute.params.horarioId) {
      fetchSchedule(router.currentRoute.params.horarioId, data => {
        schedule.value = data
        const dateInicio = new Date(Date.parse(data.inicioTurno))
        schedule.value.inicioTurno = `${dateInicio.getHours()}:${dateInicio.getMinutes()}:${dateInicio.getSeconds()}`
        const dateFin = new Date(Date.parse(data.finTurno))
        schedule.value.finTurno = `${dateFin.getHours()}:${dateFin.getMinutes()}:${dateFin.getSeconds()}`
      })
    } else {
      schedule.value = JSON.parse(JSON.stringify(blankSchedule))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSchedule)

    const onSubmit = () => {
      addingSchedule.value = true
      if (schedule.value.horarioId === 0) {
        createSchedule(schedule.value, () => {
          addingSchedule.value = false
          router.push({ name: 'apps-human-resources-schedule-list' })
        })
      } else {
        updateSchedule(schedule.value.horarioId, schedule.value, () => {
          addingSchedule.value = false
          router.push({ name: 'apps-human-resources-schedule-list' })
        })
      }
    }

    return {
      schedule,
      addingSchedule,
      required,
      max,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
