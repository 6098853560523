var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.schedule.horarioId == 0 ? _vm.$t("Routes.ScheduleAdd") : _vm.$t("Routes.ScheduleEdit"))+" ")]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('humanResources.schedule.columns.name'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.schedule.columns.name'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.schedule.nombre),callback:function ($$v) {_vm.$set(_vm.schedule, "nombre", $$v)},expression:"schedule.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('humanResources.schedule.columns.startTurn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.schedule.columns.startTurn'),"state":errors.length > 0 ? false : null}},[_c('b-form-timepicker',{attrs:{"locale":"en"},model:{value:(_vm.schedule.inicioTurno),callback:function ($$v) {_vm.$set(_vm.schedule, "inicioTurno", $$v)},expression:"schedule.inicioTurno"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('humanResources.schedule.columns.endTurn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.schedule.columns.endTurn'),"state":errors.length > 0 ? false : null}},[_c('b-form-timepicker',{attrs:{"locale":"en"},model:{value:(_vm.schedule.finTurno),callback:function ($$v) {_vm.$set(_vm.schedule, "finTurno", $$v)},expression:"schedule.finTurno"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.schedule.columns.description'),"state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{model:{value:(_vm.schedule.descripcion),callback:function ($$v) {_vm.$set(_vm.schedule, "descripcion", $$v)},expression:"schedule.descripcion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[(_vm.addingSchedule)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.schedule.horarioId == 0 ? _vm.$t("Lists.Add") : _vm.$t("Lists.Edit"))+" ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }